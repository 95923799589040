





































import { post, t } from "#root/lib/utils";
import Vue from "vue";
import { LocaleKeys, Endpoints } from "#mws/data";
import { Rules } from "#mws/data";
import { UserProfile, UserProfileSetup } from "#mws/interfaces";
import SessionModule from "#root/store/session";
import AppModule from "#root/store/app";
import Store from "#root/store/store";

export default Vue.extend({
  name: "UserProfile",
  data: () => ({
    shown: true,
    formActive: true,
    profile: {
      name: "",
      valid: false,
    },
    lk: LocaleKeys,
  }),
  methods: {
    t: t,
    setup() {
      post<UserProfileSetup, UserProfile>({
        endpoint: Endpoints.HTTP.user.profile.set,
        auth: true,
        data: {
          name: this.profile.name,
        },
      }).then((result) => {
        SessionModule.profile = result;
      });
    },
    logout() {
      Store.logout();
      AppModule.loadingAlert();
      this.$router.push("/login");
    },
  },
  computed: {
    usernameRule() {
      return this.formActive
        ? [
            (value: string) =>
              value.length <= Rules.USERNAME.MAX_CHAR ||
              t(LocaleKeys.fieldError.maxCharacters, [Rules.USERNAME.MAX_CHAR]),
            (value: string) =>
              value.length >= Rules.USERNAME.MIN_CHAR ||
              t(LocaleKeys.fieldError.minCharacters, [Rules.USERNAME.MIN_CHAR]),
            (value: string) =>
              Rules.USERNAME.REGEX.test(value) ||
              t(LocaleKeys.fieldError.usernameInvalid),
          ]
        : [];
    },
  },
});
